export type AreaOption = {
    id: string,
    label:string, 
    labelGR:string, 
    city:string, 
    area:string,
    type?:string,
}

export const NICOSIA_AREAS = [
	{
		label: 'Acropolis, Nicosia',
		id: '5',
		city: 'nicosia',
		area: 'acropolis',
		labelGR: 'Ακρόπολις, Λευκωσία',
	},
	{
		label: 'Ag Konst and Elenis, Nicosia',
		id: '6',
		city: 'nicosia',
		area: 'ag konst and elenis',
		labelGR: 'Aγιοι Κωνσταντίνος και Ελένη, Λευκωσία',
	},
	{
		label: 'Ag. Antonios, Nicosia',
		id: '7',
		city: 'nicosia',
		area: 'ag. antonios',
		labelGR: 'Aγιος Αντώνιος, Λευκωσία',
	},
	{
		label: 'Agia Eirini, Nicosia',
		id: '8',
		city: 'nicosia',
		area: 'agia eirini',
		labelGR: 'Αγία Ειρήνη, Λευκωσία',
	},
	{
		label: 'Agia Marina, Nicosia',
		id: '9',
		city: 'nicosia',
		area: 'agia marina',
		labelGR: 'Αγία Μαρίνα, Λευκωσία',
	},
	{
		label: 'Agia Sofia, Nicosia',
		id: '10',
		city: 'nicosia',
		area: 'agia sofia',
		labelGR: 'Αγία Σοφία, Λευκωσία',
	},
	{
		label: 'Agia Varvara, Nicosia',
		id: '11',
		city: 'nicosia',
		area: 'agia varvara',
		labelGR: 'Αγιά Βαρβάρα, Λευκωσία',
	},
	{
		label: 'Agioi Omologites, Nicosia',
		id: '12',
		city: 'nicosia',
		area: 'agioi omologites',
		labelGR: 'Αγίοι Ομολογητές, Λευκωσία',
	},
	{
		label: 'Agioi Trimithias, Nicosia',
		id: '13',
		city: 'nicosia',
		area: 'agioi trimithias',
		labelGR: 'Αγίοι Τριμιθιάς, Λευκωσία',
	},
	{
		label: 'Agios Andreas, Nicosia',
		id: '14',
		city: 'nicosia',
		area: 'agios andreas',
		labelGR: 'Aγιος Ανδρέας, Λευκωσία',
	},
	{
		label: 'Agios Dometios, Nicosia',
		id: '15',
		city: 'nicosia',
		area: 'agios dometios',
		labelGR: 'Άγιος Δομέτιος, Λευκωσία',
	},
	{
		label: 'Agios Epifanios, Nicosia',
		id: '16',
		city: 'nicosia',
		area: 'agios epifanios',
		labelGR: 'Αγιος Επιφάνιος, Λευκωσία',
	},
	{
		label: 'Agios Georgios Kafkalou, Nicosia',
		id: '17',
		city: 'nicosia',
		area: 'agios georgios kafkalou',
		labelGR: 'Αγιος Γεώργιος (Καυκάλλου), Λευκωσία',
	},
	{
		label: 'Agios Ioannis Malountas, Nicosia',
		id: '18',
		city: 'nicosia',
		area: 'agios ioannis malountas',
		labelGR: 'Αγιος Ιωάννης Μαλούντας, Λευκωσία',
	},
	{
		label: 'Agios Ioannis, Nicosia',
		id: '19',
		city: 'nicosia',
		area: 'agios ioannis',
		labelGR: 'Αγιος Ιωάννης, Λευκωσία',
	},
	{
		label: 'Agios Sozomenos, Nicosia',
		id: '20',
		city: 'nicosia',
		area: 'agios sozomenos',
		labelGR: "'Αγιος Σωζόμενος, Λευκωσία",
	},
	{
		label: 'Agios Theodoros Soleas, Nicosia',
		id: '21',
		city: 'nicosia',
		area: 'agios theodoros soleas',
		labelGR: 'Αγιος Θεόδωρος Σολέας, Λευκωσία',
	},
	{
		label: 'Agios Vasileios, Nicosia',
		id: '22',
		city: 'nicosia',
		area: 'agios vasileios',
		labelGR: "'Αγιος Βασίλειος, Λευκωσία",
	},
	{
		label: 'Aglantzia, Nicosia',
		id: '23',
		city: 'nicosia',
		area: 'aglantzia',
		labelGR: 'Αγλαντζιά, Λευκωσία',
	},
	{
		label: 'Agrokipia, Nicosia',
		id: '24',
		city: 'nicosia',
		area: 'agrokipia',
		labelGR: 'Αγροκηπιά, Λευκωσία',
	},
	{
		label: 'Akaki, Nicosia',
		id: '25',
		city: 'nicosia',
		area: 'akaki',
		labelGR: 'Ακάκι, Λευκωσία',
	},
	{
		label: 'Alampra, Nicosia',
		id: '26',
		city: 'nicosia',
		area: 'alampra',
		labelGR: 'Αλάμπρα, Λευκωσία',
	},
	{
		label: 'Anageia, Nicosia',
		id: '27',
		city: 'nicosia',
		area: 'anageia',
		labelGR: 'Ανάγεια, Λευκωσία',
	},
	{
		label: 'Analiontas, Nicosia',
		id: '28',
		city: 'nicosia',
		area: 'analiontas',
		labelGR: 'Αναλιόντας, Λευκωσία',
	},
	{
		label: 'Anthoupolis, Nicosia',
		id: '29',
		city: 'nicosia',
		area: 'anthoupolis',
		labelGR: 'Ανθούπολη, Λευκωσία',
	},
	{
		label: 'Archangelos, Nicosia',
		id: '30',
		city: 'nicosia',
		area: 'archangelos',
		labelGR: 'Αρχάγγελος, Λευκωσία',
	},
	{
		label: 'Arediou, Nicosia',
		id: '31',
		city: 'nicosia',
		area: 'arediou',
		labelGR: 'Αρεδιού, Λευκωσία',
	},
	{
		label: 'Askas, Nicosia',
		id: '32',
		city: 'nicosia',
		area: 'askas',
		labelGR: 'Ασκάς, Λευκωσία',
	},
	{
		label: 'Astromeritis, Nicosia',
		id: '33',
		city: 'nicosia',
		area: 'astromeritis',
		labelGR: 'Αστρομερίτης, Λευκωσία',
	},
	{
		label: 'Dali, Nicosia',
		id: '34',
		city: 'nicosia',
		area: 'dali',
		labelGR: 'Δάλι, Λευκωσία',
	},
	{
		label: 'Dasoupolis, Nicosia',
		id: '35',
		city: 'nicosia',
		area: 'dasoupolis',
		labelGR: 'Δασούπολη, Λευκωσία',
	},
	{
		label: 'Deftera Kato, Nicosia',
		id: '36',
		city: 'nicosia',
		area: 'deftera kato',
		labelGR: 'Κάτω Δευτερά, Λευκωσία',
	},
	{
		label: 'Deftera Pano, Nicosia',
		id: '37',
		city: 'nicosia',
		area: 'deftera pano',
		labelGR: 'Πάνω Δευτερά, Λευκωσία',
	},
	{
		label: 'Deneia, Nicosia',
		id: '38',
		city: 'nicosia',
		area: 'deneia',
		labelGR: 'Δένεια, Λευκωσία',
	},
	{
		label: 'Egkomi, Nicosia',
		id: '39',
		city: 'nicosia',
		area: 'egkomi',
		labelGR: 'Έγκωμη, Λευκωσία',
	},
	{
		label: 'Episkopeio, Nicosia',
		id: '40',
		city: 'nicosia',
		area: 'episkopeio',
		labelGR: 'Επισκοπειό, Λευκωσία',
	},
	{
		label: 'Ergates, Nicosia',
		id: '41',
		city: 'nicosia',
		area: 'ergates',
		labelGR: 'Εργάτες, Λευκωσία',
	},
	{
		label: 'Evrychou, Nicosia',
		id: '42',
		city: 'nicosia',
		area: 'evrychou',
		labelGR: 'Ευρύχου, Λευκωσία',
	},
	{
		label: 'Faneromeni, Nicosia',
		id: '43',
		city: 'nicosia',
		area: 'faneromeni',
		labelGR: 'Φανερωμένη, Λευκωσία',
	},
	{
		label: 'Farmakas, Nicosia',
		id: '44',
		city: 'nicosia',
		area: 'farmakas',
		labelGR: 'Φαρμακάς, Λευκωσία',
	},
	{
		label: 'Fikardou, Nicosia',
		id: '45',
		city: 'nicosia',
		area: 'fikardou',
		labelGR: 'Φικάρδου, Λευκωσία',
	},
	{
		label: 'Flasou Kato, Nicosia',
		id: '46',
		city: 'nicosia',
		area: 'flasou kato',
		labelGR: 'Κάτω Φλάσου, Λευκωσία',
	},
	{
		label: 'Fterikoudi, Nicosia',
		id: '47',
		city: 'nicosia',
		area: 'fterikoudi',
		labelGR: 'Φτερικούδι, Λευκωσία',
	},
	{
		label: 'Galata, Nicosia',
		id: '48',
		city: 'nicosia',
		area: 'galata',
		labelGR: 'Γαλατάς, Λευκωσία',
	},
	{
		label: 'Geri, Nicosia',
		id: '49',
		city: 'nicosia',
		area: 'geri',
		labelGR: 'Γέρι, Λευκωσία',
	},
	{
		label: 'Gourri, Nicosia',
		id: '50',
		city: 'nicosia',
		area: 'gourri',
		labelGR: 'Γούρρη, Λευκωσία',
	},
	{
		label: 'GSP Area, Nicosia',
		id: '51',
		city: 'nicosia',
		area: 'gsp area',
		labelGR: 'Στάδιο ΓΣΠ, Λευκωσία',
	},
	{
		label: 'Ilioupoli, Nicosia',
		id: '52',
		city: 'nicosia',
		area: 'ilioupoli',
		labelGR: 'Ηλιούπολη, Λευκωσία',
	},
	{
		label: 'Kaimakli, Nicosia',
		id: '53',
		city: 'nicosia',
		area: 'kaimakli',
		labelGR: 'Καϊμακλί, Λευκωσία',
	},
	{
		label: 'Kakopetria, Nicosia',
		id: '54',
		city: 'nicosia',
		area: 'kakopetria',
		labelGR: 'Κακοπετριά, Λευκωσία',
	},
	{
		label: 'Kaliana, Nicosia',
		id: '55',
		city: 'nicosia',
		area: 'kaliana',
		labelGR: 'Καλιάνα, Λευκωσία',
	},
	{
		label: 'Kalithea, Nicosia',
		id: '56',
		city: 'nicosia',
		area: 'kalithea',
		labelGR: 'Καλλιθέα, Λευκωσία',
	},
	{
		label: 'Kalo Chorio, Nicosia',
		id: '57',
		city: 'nicosia',
		area: 'kalo chorio',
		labelGR: 'Καλό Χωριό, Λευκωσία',
	},
	{
		label: 'Kalopanagiotis, Nicosia',
		id: '58',
		city: 'nicosia',
		area: 'kalopanagiotis',
		labelGR: 'Καλοπαναγιώτης, Λευκωσία',
	},
	{
		label: 'Kampi, Nicosia',
		id: '59',
		city: 'nicosia',
		area: 'kampi',
		labelGR: 'Καμπί, Λευκωσία',
	},
	{
		label: 'Kampia, Nicosia',
		id: '60',
		city: 'nicosia',
		area: 'kampia',
		labelGR: 'Καμπιά, Λευκωσία',
	},
	{
		label: 'Kampos, Nicosia',
		id: '61',
		city: 'nicosia',
		area: 'kampos',
		labelGR: 'Κάμπος, Λευκωσία',
	},
	{
		label: 'Kannavia, Nicosia',
		id: '62',
		city: 'nicosia',
		area: 'kannavia',
		labelGR: 'Καννάβια, Λευκωσία',
	},
	{
		label: 'Kapedes, Nicosia',
		id: '63',
		city: 'nicosia',
		area: 'kapedes',
		labelGR: 'Καπέδες, Λευκωσία',
	},
	{
		label: 'Klirou, Nicosia',
		id: '64',
		city: 'nicosia',
		area: 'klirou',
		labelGR: 'Κλήρου, Λευκωσία',
	},
	{
		label: 'Kokkinotrimithia, Nicosia',
		id: '65',
		city: 'nicosia',
		area: 'kokkinotrimithia',
		labelGR: 'Κοκκινοτριμιθιά, Λευκωσία',
	},
	{
		label: 'Korakou, Nicosia',
		id: '66',
		city: 'nicosia',
		area: 'korakou',
		labelGR: 'Κοράκου, Λευκωσία',
	},
	{
		label: 'Kourdali, Nicosia',
		id: '67',
		city: 'nicosia',
		area: 'kourdali',
		labelGR: 'Κούρδαλι, Λευκωσία',
	},
	{
		label: 'Koutrafas Kato, Nicosia',
		id: '68',
		city: 'nicosia',
		area: 'koutrafas kato',
		labelGR: 'Κάτω Κουτραφάς, Λευκωσία',
	},
	{
		label: 'Lakatameia, Nicosia',
		id: '69',
		city: 'nicosia',
		area: 'lakatameia',
		labelGR: 'Λακατάμια, Λευκωσία',
	},
	{
		label: 'Latsia, Nicosia',
		id: '70',
		city: 'nicosia',
		area: 'latsia',
		labelGR: 'Λατσιά, Λευκωσία',
	},
	{
		label: 'Lazanias, Nicosia',
		id: '71',
		city: 'nicosia',
		area: 'lazanias',
		labelGR: 'Λαζανιάς, Λευκωσία',
	},
	{
		label: 'Lykabittos, Nicosia',
		id: '72',
		city: 'nicosia',
		area: 'lykabittos',
		labelGR: 'Λυκαβηττός, Λευκωσία',
	},
	{
		label: 'Lympia, Nicosia',
		id: '73',
		city: 'nicosia',
		area: 'lympia',
		labelGR: 'Λύμπια, Λευκωσία',
	},
	{
		label: 'Lythrodontas, Nicosia',
		id: '74',
		city: 'nicosia',
		area: 'lythrodontas',
		labelGR: 'Λυθροδόντας, Λευκωσία',
	},
	{
		label: 'Makedonitissa, Nicosia',
		id: '75',
		city: 'nicosia',
		area: 'makedonitissa',
		labelGR: 'Μακεδονίτισσα, Λευκωσία',
	},
	{
		label: 'Malounta, Nicosia',
		id: '76',
		city: 'nicosia',
		area: 'malounta',
		labelGR: 'Μαλούντα, Λευκωσία',
	},
	{
		label: 'Mammari, Nicosia',
		id: '77',
		city: 'nicosia',
		area: 'mammari',
		labelGR: 'Μάμμαρι, Λευκωσία',
	},
	{
		label: 'Margi, Nicosia',
		id: '78',
		city: 'nicosia',
		area: 'margi',
		labelGR: 'Μαρκί, Λευκωσία',
	},
	{
		label: 'Mathiatis, Nicosia',
		id: '79',
		city: 'nicosia',
		area: 'mathiatis',
		labelGR: 'Μαθιάτης, Λευκωσία',
	},
	{
		label: 'Meniko, Nicosia',
		id: '80',
		city: 'nicosia',
		area: 'meniko',
		labelGR: 'Μένικο, Λευκωσία',
	},
	{
		label: 'Mitsero, Nicosia',
		id: '81',
		city: 'nicosia',
		area: 'mitsero',
		labelGR: 'Μιτσερό, Λευκωσία',
	},
	{
		label: 'Moni Kato, Nicosia',
		id: '82',
		city: 'nicosia',
		area: 'moni kato',
		labelGR: 'Κάτω Μονή, Λευκωσία',
	},
	{
		label: 'Mosfili, Nicosia',
		id: '83',
		city: 'nicosia',
		area: 'mosfili',
		labelGR: 'Μοσφίλι, Λευκωσία',
	},
	{
		label: 'Mylikouri, Nicosia',
		id: '84',
		city: 'nicosia',
		area: 'mylikouri',
		labelGR: 'Μυλικούρι, Λευκωσία',
	},
	{
		label: 'Nea Ledras, Nicosia',
		id: '85',
		city: 'nicosia',
		area: 'nea ledras',
		labelGR: 'Νέα Λήδρα, Λευκωσία',
	},
	{
		label: 'Nikitari, Nicosia',
		id: '86',
		city: 'nicosia',
		area: 'nikitari',
		labelGR: 'Νικητάρι, Λευκωσία',
	},
	{
		label: 'Nisou, Nicosia',
		id: '87',
		city: 'nicosia',
		area: 'nisou',
		labelGR: 'Νήσου, Λευκωσία',
	},
	{
		label: 'Omerie, Nicosia',
		id: '88',
		city: 'nicosia',
		area: 'omerie',
		labelGR: 'Ομεριέ, Λευκωσία',
	},
	{
		label: 'Orounta, Nicosia',
		id: '89',
		city: 'nicosia',
		area: 'orounta',
		labelGR: 'Ορούντα, Λευκωσία',
	},
	{
		label: 'Pachyammos, Nicosia',
		id: '90',
		city: 'nicosia',
		area: 'pachyammos',
		labelGR: 'Παχύαμμος, Λευκωσία',
	},
	{
		label: 'Palaichori Morfou, Nicosia',
		id: '91',
		city: 'nicosia',
		area: 'palaichori morfou',
		labelGR: 'Παλαιχώρι Μόρφου, Λευκωσία',
	},
	{
		label: 'Palaichori Oreinis, Nicosia',
		id: '92',
		city: 'nicosia',
		area: 'palaichori oreinis',
		labelGR: 'Παλαιχώρι Ορεινής, Λευκωσία',
	},
	{
		label: 'Palaiometocho, Nicosia',
		id: '93',
		city: 'nicosia',
		area: 'palaiometocho',
		labelGR: 'Παλαιομέτοχο, Λευκωσία',
	},
	{
		label: 'Palouriotissa, Nicosia',
		id: '94',
		city: 'nicosia',
		area: 'palouriotissa',
		labelGR: 'Παλουριώτισσα, Λευκωσία',
	},
	{
		label: 'Panagia, Nicosia',
		id: '95',
		city: 'nicosia',
		area: 'panagia',
		labelGR: 'Παναγία, Λευκωσία',
	},
	{
		label: 'Parissinos, Nicosia',
		id: '96',
		city: 'nicosia',
		area: 'parissinos',
		labelGR: 'Παρισσινός, Λευκωσία',
	},
	{
		label: 'Pedoulas, Nicosia',
		id: '97',
		city: 'nicosia',
		area: 'pedoulas',
		labelGR: 'Πεδουλάς, Λευκωσία',
	},
	{
		label: 'Pera Chorio, Nicosia',
		id: '98',
		city: 'nicosia',
		area: 'pera chorio',
		labelGR: 'Πέρα Χωριό, Λευκωσία',
	},
	{
		label: 'Pera, Nicosia',
		id: '99',
		city: 'nicosia',
		area: 'pera',
		labelGR: 'Πέρα, Λευκωσία',
	},
	{
		label: 'Peristerona, Nicosia',
		id: '100',
		city: 'nicosia',
		area: 'peristerona',
		labelGR: 'Περιστερώνα, Λευκωσία',
	},
	{
		label: 'Pigenia, Nicosia',
		id: '101',
		city: 'nicosia',
		area: 'pigenia',
		labelGR: 'Πηγένια, Λευκωσία',
	},
	{
		label: 'Platanistasa, Nicosia',
		id: '102',
		city: 'nicosia',
		area: 'platanistasa',
		labelGR: 'Πλατανιστάσα, Λευκωσία',
	},
	{
		label: 'Politiko, Nicosia',
		id: '103',
		city: 'nicosia',
		area: 'politiko',
		labelGR: 'Πολιτικό, Λευκωσία',
	},
	{
		label: 'Polystypos, Nicosia',
		id: '104',
		city: 'nicosia',
		area: 'polystypos',
		labelGR: 'Πολύστυπος, Λευκωσία',
	},
	{
		label: 'Potami, Nicosia',
		id: '105',
		city: 'nicosia',
		area: 'potami',
		labelGR: 'Ποτάμι, Λευκωσία',
	},
	{
		label: 'Potamia, Nicosia',
		id: '106',
		city: 'nicosia',
		area: 'potamia',
		labelGR: 'Ποταμιά, Λευκωσία',
	},
	{
		label: 'Psimolofou, Nicosia',
		id: '107',
		city: 'nicosia',
		area: 'psimolofou',
		labelGR: 'Ψiμολόφου, Λευκωσία',
	},
	{
		label: 'Pyrgos Kato, Nicosia',
		id: '108',
		city: 'nicosia',
		area: 'pyrgos kato',
		labelGR: 'Κάτω Πύργος, Λευκωσία',
	},
	{
		label: 'Pyrgos Pano, Nicosia',
		id: '109',
		city: 'nicosia',
		area: 'pyrgos pano',
		labelGR: 'Πάνω Πύργος, Λευκωσία',
	},
	{
		label: 'Saranti, Nicosia',
		id: '110',
		city: 'nicosia',
		area: 'saranti',
		labelGR: 'Σαράντι, Λευκωσία',
	},
	{
		label: 'Sia, Nicosia',
		id: '111',
		city: 'nicosia',
		area: 'sia',
		labelGR: 'Σιά, Λευκωσία',
	},
	{
		label: 'Sina Oros, Nicosia',
		id: '112',
		city: 'nicosia',
		area: 'sina oros',
		labelGR: 'Σινά Όρος, Λευκωσία',
	},
	{
		label: 'Spilia, Nicosia',
		id: '113',
		city: 'nicosia',
		area: 'spilia',
		labelGR: 'Σπήλια, Λευκωσία',
	},
	{
		label: 'Stelmek Area, Nicosia',
		id: '114',
		city: 'nicosia',
		area: 'stelmek area',
		labelGR: 'Stelmek Area, Λευκωσία',
	},
	{
		label: 'Strovolos, Nicosia',
		id: '115',
		city: 'nicosia',
		area: 'strovolos',
		labelGR: 'Στρόβολος, Λευκωσία',
	},
	{
		label: 'Synoikismos, Nicosia',
		id: '116',
		city: 'nicosia',
		area: 'synoikismos',
		labelGR: 'Συνοικισμός, Λευκωσία',
	},
	{
		label: 'Temvria, Nicosia',
		id: '117',
		city: 'nicosia',
		area: 'temvria',
		labelGR: 'Τεμβριά, Λευκωσία',
	},
	{
		label: 'Trypiotis, Nicosia',
		id: '118',
		city: 'nicosia',
		area: 'trypiotis',
		labelGR: 'Τρυπιώτη, Λευκωσία',
	},
	{
		label: 'Tsakistra, Nicosia',
		id: '119',
		city: 'nicosia',
		area: 'tsakistra',
		labelGR: 'Τσακίστρα, Λευκωσία',
	},
	{
		label: 'Tseri, Nicosia',
		id: '120',
		city: 'nicosia',
		area: 'tseri',
		labelGR: 'Τσέρι, Λευκωσία',
	},
	{
		label: 'Tymbou, Nicosia',
		id: '121',
		city: 'nicosia',
		area: 'tymbou',
		labelGR: 'Τύμβου, Λευκωσία',
	},
	{
		label: 'Walled Old City, Nicosia',
		id: '122',
		city: 'nicosia',
		area: 'walled old city',
		labelGR: 'Εντός των Τοιχών Παλαιά Πόλη, Λευκωσία',
	},
	{
		label: 'Xyliatos, Nicosia',
		id: '123',
		city: 'nicosia',
		area: 'xyliatos',
		labelGR: 'Ξυλιάτος, Λευκωσία',
	},
	{
		label: 'Xyliatou, Nicosia',
		id: '124',
		city: 'nicosia',
		area: 'xyliatou',
		labelGR: 'Ξυλιάτου, Λευκωσία',
	},
]

export const PAPHOS_AREAS = [
	{
		label: 'Acheleia, Paphos',
		id: '264',
		city: 'paphos',
		area: 'acheleia',
		labelGR: 'Αχέλεια, Πάφος',
	},
	{
		label: 'Agia Marina Chrysochous, Paphos',
		id: '265',
		city: 'paphos',
		area: 'agia marina chrysochous',
		labelGR: 'Αγία Μαρίνα Χρυσοχούς, Πάφος',
	},
	{
		label: 'Agia Marina Kelokedaron, Paphos',
		id: '266',
		city: 'paphos',
		area: 'agia marina kelokedaron',
		labelGR: 'Αγία Μαρίνα Κελοκεδάρων, Πάφος',
	},
	{
		label: 'Agia Marinouda, Paphos',
		id: '267',
		city: 'paphos',
		area: 'agia marinouda',
		labelGR: 'Αγία Μαρινούδα, Πάφος',
	},
	{
		label: 'Agia Varvara, Paphos',
		id: '268',
		city: 'paphos',
		area: 'agia varvara',
		labelGR: 'Αγία Βαρβάρα, Πάφος',
	},
	{
		label: 'Agios Dimitrianos, Paphos',
		id: '269',
		city: 'paphos',
		area: 'agios dimitrianos',
		labelGR: 'Άγιος Δημητριανός , Πάφος',
	},
	{
		label: 'Agios Georgios, Paphos',
		id: '270',
		city: 'paphos',
		area: 'agios georgios',
		labelGR: 'Αγίου Γεωργίου, Πάφος',
	},
	{
		label: 'Agios Pavlos, Paphos',
		id: '271',
		city: 'paphos',
		area: 'agios pavlos',
		labelGR: 'Άγιος Παύλος, Πάφος',
	},
	{
		label: 'Agios Theodoros, Paphos',
		id: '272',
		city: 'paphos',
		area: 'agios theodoros',
		labelGR: 'Άγιος Θεόδωρος, Πάφος',
	},
	{
		label: 'Akourdaleia Kato, Paphos',
		id: '273',
		city: 'paphos',
		area: 'akourdaleia kato',
		labelGR: 'Κάτω Ακουρδάλεια, Πάφος',
	},
	{
		label: 'Akourdaleia Pano, Paphos',
		id: '274',
		city: 'paphos',
		area: 'akourdaleia pano',
		labelGR: 'Πάνω Ακουρδάλεια, Πάφος',
	},
	{
		label: 'Akoursos, Paphos',
		id: '275',
		city: 'paphos',
		area: 'akoursos',
		labelGR: 'Ακουρσός, Πάφος',
	},
	{
		label: 'Amargeti, Paphos',
		id: '276',
		city: 'paphos',
		area: 'amargeti',
		labelGR: 'Αμαργέτη, Πάφος',
	},
	{
		label: 'Anarita, Paphos',
		id: '277',
		city: 'paphos',
		area: 'anarita',
		labelGR: 'Αναρίτα, Πάφος',
	},
	{
		label: 'Anavargos, Paphos',
		id: '278',
		city: 'paphos',
		area: 'anavargos',
		labelGR: 'Αναβαργός, Πάφος',
	},
	{
		label: 'Androlikou, Paphos',
		id: '279',
		city: 'paphos',
		area: 'androlikou',
		labelGR: 'Ανδρολίκου, Πάφος',
	},
	{
		label: 'Aphrodite hills Kouklia, Paphos',
		id: '280',
		city: 'paphos',
		area: 'aphrodite hills kouklia',
		labelGR: 'Αφροδίτη Χίλς Κούκλια, Πάφος',
	},
	{
		label: 'Archimandrita Kato, Paphos',
		id: '281',
		city: 'paphos',
		area: 'archimandrita kato',
		labelGR: 'Κάτω Aρχιμανδρίτα, Πάφος',
	},
	{
		label: 'Archimandrita Pano, Paphos',
		id: '282',
		city: 'paphos',
		area: 'archimandrita pano',
		labelGR: 'Πάνω Αρχιμανδρίτα, Πάφος',
	},
	{
		label: 'Argaka, Paphos',
		id: '283',
		city: 'paphos',
		area: 'argaka',
		labelGR: 'Αργάκα, Πάφος',
	},
	{
		label: 'Arminou, Paphos',
		id: '284',
		city: 'paphos',
		area: 'arminou',
		labelGR: 'Αρμίνου, Πάφος',
	},
	{
		label: 'Armou, Paphos',
		id: '285',
		city: 'paphos',
		area: 'armou',
		labelGR: 'Άρμου, Πάφος',
	},
	{
		label: 'Arodes Kato, Paphos',
		id: '286',
		city: 'paphos',
		area: 'arodes kato',
		labelGR: 'Κάτω Αρόδες, Πάφος',
	},
	{
		label: 'Arodes Pano, Paphos',
		id: '287',
		city: 'paphos',
		area: 'arodes pano',
		labelGR: 'Πάνω Αρόδες, Πάφος',
	},
	{
		label: 'Chlorakas, Paphos',
		id: '288',
		city: 'paphos',
		area: 'chlorakas',
		labelGR: 'Χλώρακα, Πάφος',
	},
	{
		label: 'Choletria, Paphos',
		id: '289',
		city: 'paphos',
		area: 'choletria',
		labelGR: 'Χολέτρια, Πάφος',
	},
	{
		label: 'Choulou, Paphos',
		id: '290',
		city: 'paphos',
		area: 'choulou',
		labelGR: 'Χούλου, Πάφος',
	},
	{
		label: 'Chrysochou, Paphos',
		id: '291',
		city: 'paphos',
		area: 'chrysochou',
		labelGR: 'Χρυσοχού, Πάφος',
	},
	{
		label: 'Drouseia, Paphos',
		id: '292',
		city: 'paphos',
		area: 'drouseia',
		labelGR: 'Δρούσεια, Πάφος',
	},
	{
		label: 'Drymou, Paphos',
		id: '293',
		city: 'paphos',
		area: 'drymou',
		labelGR: 'Δρύμου, Πάφος',
	},
	{
		label: 'Empa, Paphos',
		id: '294',
		city: 'paphos',
		area: 'empa',
		labelGR: 'Έμπα, Πάφος',
	},
	{
		label: 'Episkopi, Paphos',
		id: '295',
		city: 'paphos',
		area: 'episkopi',
		labelGR: 'Επισκοπή, Πάφος',
	},
	{
		label: 'Filousa Kelokedaron, Paphos',
		id: '296',
		city: 'paphos',
		area: 'filousa kelokedaron',
		labelGR: 'Φιλούσα, Πάφος',
	},
	{
		label: 'Fyti, Paphos',
		id: '297',
		city: 'paphos',
		area: 'fyti',
		labelGR: 'Φύτη, Πάφος',
	},
	{
		label: 'Galataria, Paphos',
		id: '298',
		city: 'paphos',
		area: 'galataria',
		labelGR: 'Γαλαταριά, Πάφος',
	},
	{
		label: 'Geroskipou, Paphos',
		id: '299',
		city: 'paphos',
		area: 'geroskipou',
		labelGR: 'Γεροσκήπου, Πάφος',
	},
	{
		label: 'Geroskipou, Paphos',
		id: '300',
		city: 'paphos',
		area: 'geroskipou',
		labelGR: 'Γεροσκήπου, Πάφος',
	},
	{
		label: 'Giolou, Paphos',
		id: '301',
		city: 'paphos',
		area: 'giolou',
		labelGR: 'Γιόλου, Πάφος',
	},
	{
		label: 'Goudi, Paphos',
		id: '302',
		city: 'paphos',
		area: 'goudi',
		labelGR: 'Γουδί, Πάφος',
	},
	{
		label: 'Ineia, Paphos',
		id: '303',
		city: 'paphos',
		area: 'ineia',
		labelGR: 'Ινεια, Πάφος',
	},
	{
		label: 'Kallepeia, Paphos',
		id: '304',
		city: 'paphos',
		area: 'kallepeia',
		labelGR: 'Καλλέπεια, Πάφος',
	},
	{
		label: 'Kannaviou, Paphos',
		id: '305',
		city: 'paphos',
		area: 'kannaviou',
		labelGR: 'Κανναβιού, Πάφος',
	},
	{
		label: 'Kathikas, Paphos',
		id: '306',
		city: 'paphos',
		area: 'kathikas',
		labelGR: 'Κάθικας, Πάφος',
	},
	{
		label: 'Kelokedara, Paphos',
		id: '307',
		city: 'paphos',
		area: 'kelokedara',
		labelGR: 'Κελοκέδαρα, Πάφος',
	},
	{
		label: 'Kissonerga, Paphos',
		id: '308',
		city: 'paphos',
		area: 'kissonerga',
		labelGR: 'Κισσόνεργα, Πάφος',
	},
	{
		label: 'Koili, Paphos',
		id: '309',
		city: 'paphos',
		area: 'koili',
		labelGR: 'Κοίλη, Πάφος',
	},
	{
		label: 'Koilineia, Paphos',
		id: '310',
		city: 'paphos',
		area: 'koilineia',
		labelGR: 'Κοιλίνεια, Πάφος',
	},
	{
		label: 'Koloni, Paphos',
		id: '311',
		city: 'paphos',
		area: 'koloni',
		labelGR: 'Κολώνη, Πάφος',
	},
	{
		label: 'Konia, Paphos',
		id: '312',
		city: 'paphos',
		area: 'konia',
		labelGR: 'Κονιά, Πάφος',
	},
	{
		label: 'Kouklia, Paphos',
		id: '313',
		city: 'paphos',
		area: 'kouklia',
		labelGR: 'Κούκλια, Πάφος',
	},
	{
		label: 'Kourdaka, Paphos',
		id: '314',
		city: 'paphos',
		area: 'kourdaka',
		labelGR: 'Κούρδακα, Πάφος',
	},
	{
		label: 'Kritou Marottou, Paphos',
		id: '315',
		city: 'paphos',
		area: 'kritou marottou',
		labelGR: 'Κρίτου Μαρόττου, Πάφος',
	},
	{
		label: 'Kritou Tera, Paphos',
		id: '316',
		city: 'paphos',
		area: 'kritou tera',
		labelGR: 'Κρίτου Τέρα, Πάφος',
	},
	{
		label: 'Kynousa, Paphos',
		id: '317',
		city: 'paphos',
		area: 'kynousa',
		labelGR: 'Κυνούσα, Πάφος',
	},
	{
		label: 'Lasa, Paphos',
		id: '318',
		city: 'paphos',
		area: 'lasa',
		labelGR: 'Λάσα, Πάφος',
	},
	{
		label: 'Latsi, Paphos',
		id: '319',
		city: 'paphos',
		area: 'latsi',
		labelGR: 'Λατσί, Πάφος',
	},
	{
		label: 'Lemona, Paphos',
		id: '320',
		city: 'paphos',
		area: 'lemona',
		labelGR: 'Λεμώνα, Πάφος',
	},
	{
		label: 'Lempa, Paphos',
		id: '321',
		city: 'paphos',
		area: 'lempa',
		labelGR: 'Λέμπα, Πάφος',
	},
	{
		label: 'Letymvou, Paphos',
		id: '322',
		city: 'paphos',
		area: 'letymvou',
		labelGR: 'Λετύμβου, Πάφος',
	},
	{
		label: 'Lysos, Paphos',
		id: '323',
		city: 'paphos',
		area: 'lysos',
		labelGR: 'Λυσός, Πάφος',
	},
	{
		label: 'Mamonia, Paphos',
		id: '324',
		city: 'paphos',
		area: 'mamonia',
		labelGR: 'Μαμώνια, Πάφος',
	},
	{
		label: 'Mandria, Paphos',
		id: '325',
		city: 'paphos',
		area: 'mandria',
		labelGR: 'Μανδριά, Πάφος',
	},
	{
		label: 'Marathounta, Paphos',
		id: '326',
		city: 'paphos',
		area: 'marathounta',
		labelGR: 'Μαραθούντα, Πάφος',
	},
	{
		label: 'Mesa Chorio, Paphos',
		id: '327',
		city: 'paphos',
		area: 'mesa chorio',
		labelGR: 'Μέσα Χωριό , Πάφος',
	},
	{
		label: 'Mesana, Paphos',
		id: '328',
		city: 'paphos',
		area: 'mesana',
		labelGR: 'Μέσανα, Πάφος',
	},
	{
		label: 'Mesogi, Paphos',
		id: '329',
		city: 'paphos',
		area: 'mesogi',
		labelGR: 'Μεσόγη, Πάφος',
	},
	{
		label: 'Milia, Paphos',
		id: '330',
		city: 'paphos',
		area: 'milia',
		labelGR: 'Μηλιά, Πάφος',
	},
	{
		label: 'Mousere, Paphos',
		id: '331',
		city: 'paphos',
		area: 'mousere',
		labelGR: 'Μούσερε, Πάφος',
	},
	{
		label: 'Moutallos, Paphos',
		id: '332',
		city: 'paphos',
		area: 'moutallos',
		labelGR: 'Μούτταλλος, Πάφος',
	},
	{
		label: 'Nata, Paphos',
		id: '333',
		city: 'paphos',
		area: 'nata',
		labelGR: 'Νατά, Πάφος',
	},
	{
		label: 'Nea Dimmata, Paphos',
		id: '334',
		city: 'paphos',
		area: 'nea dimmata',
		labelGR: 'Νέα Δήμματα, Πάφος',
	},
	{
		label: 'Neo Chorio, Paphos',
		id: '335',
		city: 'paphos',
		area: 'neo chorio',
		labelGR: 'Νέο Χωριό, Πάφος',
	},
	{
		label: 'Panagia Kato, Paphos',
		id: '336',
		city: 'paphos',
		area: 'panagia kato',
		labelGR: 'Κάτω Παναγιά, Πάφος',
	},
	{
		label: 'Pegeia, Paphos',
		id: '337',
		city: 'paphos',
		area: 'pegeia',
		labelGR: 'Πέγεια, Πάφος',
	},
	{
		label: 'Pentalia, Paphos',
		id: '338',
		city: 'paphos',
		area: 'pentalia',
		labelGR: 'Πενταλιά, Πάφος',
	},
	{
		label: 'Peristerona, Paphos',
		id: '339',
		city: 'paphos',
		area: 'peristerona',
		labelGR: 'Περιστερώνα, Πάφος',
	},
	{
		label: 'Pitargou, Paphos',
		id: '340',
		city: 'paphos',
		area: 'pitargou',
		labelGR: 'Πιταργού, Πάφος',
	},
	{
		label: 'Polemi, Paphos',
		id: '341',
		city: 'paphos',
		area: 'polemi',
		labelGR: 'Πολέμι, Πάφος',
	},
	{
		label: 'Polis Chrysochous, Paphos',
		id: '342',
		city: 'paphos',
		area: 'polis chrysochous',
		labelGR: 'Πόλη Χρυσοχούς, Πάφος',
	},
	{
		label: 'Pomos, Paphos',
		id: '343',
		city: 'paphos',
		area: 'pomos',
		labelGR: 'Πωμός, Πάφος',
	},
	{
		label: 'Praitori, Paphos',
		id: '344',
		city: 'paphos',
		area: 'praitori',
		labelGR: 'Πραιτώρι, Πάφος',
	},
	{
		label: 'Prastio, Paphos',
		id: '345',
		city: 'paphos',
		area: 'prastio',
		labelGR: 'Πραστιό, Πάφος',
	},
	{
		label: 'Prodromi, Paphos',
		id: '346',
		city: 'paphos',
		area: 'prodromi',
		labelGR: 'Προδρόμι, Πάφος',
	},
	{
		label: 'Psathi, Paphos',
		id: '347',
		city: 'paphos',
		area: 'psathi',
		labelGR: 'Ψάθι, Πάφος',
	},
	{
		label: 'Salamiou, Paphos',
		id: '348',
		city: 'paphos',
		area: 'salamiou',
		labelGR: 'Σαλαμιού, Πάφος',
	},
	{
		label: 'Simou, Paphos',
		id: '349',
		city: 'paphos',
		area: 'simou',
		labelGR: 'Σίμου, Πάφος',
	},
	{
		label: 'Skoulli, Paphos',
		id: '350',
		city: 'paphos',
		area: 'skoulli',
		labelGR: 'Σκούλλη, Πάφος',
	},
	{
		label: 'Souskiou, Paphos',
		id: '351',
		city: 'paphos',
		area: 'souskiou',
		labelGR: 'Σουσκιού, Πάφος',
	},
	{
		label: 'Statos, Paphos',
		id: '352',
		city: 'paphos',
		area: 'statos',
		labelGR: 'Στατός, Πάφος',
	},
	{
		label: 'Steni, Paphos',
		id: '353',
		city: 'paphos',
		area: 'steni',
		labelGR: 'Στενή, Πάφος',
	},
	{
		label: 'Stroumpi Kato, Paphos',
		id: '354',
		city: 'paphos',
		area: 'stroumpi kato',
		labelGR: 'Κάτω Στρουμπί, Πάφος',
	},
	{
		label: 'Stroumpi Pano, Paphos',
		id: '355',
		city: 'paphos',
		area: 'stroumpi pano',
		labelGR: 'Πάνω Στρουμπί, Πάφος',
	},
	{
		label: 'Tala, Paphos',
		id: '356',
		city: 'paphos',
		area: 'tala',
		labelGR: 'Τάλα, Πάφος',
	},
	{
		label: 'Theletra, Paphos',
		id: '357',
		city: 'paphos',
		area: 'theletra',
		labelGR: 'Θελέτρα, Πάφος',
	},
	{
		label: 'Timi, Paphos',
		id: '358',
		city: 'paphos',
		area: 'timi',
		labelGR: 'Τιμή, Πάφος',
	},
	{
		label: 'Tombs Of the Kings, Paphos',
		id: '359',
		city: 'paphos',
		area: 'tombs of the kings',
		labelGR: 'Τάφοι των Βασιλέων, Πάφος',
	},
	{
		label: 'Trachypedoula, Paphos',
		id: '360',
		city: 'paphos',
		area: 'trachypedoula',
		labelGR: 'Τραχυπέδουλα, Πάφος',
	},
	{
		label: 'Tremithousa, Paphos',
		id: '361',
		city: 'paphos',
		area: 'tremithousa',
		labelGR: 'Τρεμιθούσα, Πάφος',
	},
	{
		label: 'Tsada, Paphos',
		id: '362',
		city: 'paphos',
		area: 'tsada',
		labelGR: 'Τσάδα, Πάφος',
	},
	{
		label: 'Universal, Paphos',
		id: '363',
		city: 'paphos',
		area: 'universal',
		labelGR: 'Universal, Πάφος',
	},
	{
		label: 'Kato Paphos, Paphos',
		id: '444',
		city: 'paphos',
		area: 'kato paphos',
		labelGR: 'Κάτω Πάφος, Πάφος'
	},
	{
		label: 'Vasiliko, Paphos',
		id: '447',
		city: 'paphos',
		area: 'vasiliko',
		labelGR: 'Βασιλικό, Πάφος'
	}
]

export const FAMAGUSTA_AREAS = [
	{
		label: 'Achna, Famagusta',
		id: '364',
		city: 'famagusta',
		area: 'achna',
		labelGR: 'Άχνα, Αμμόχωστος',
	},
	{
		label: 'Agia Napa, Famagusta',
		id: '365',
		city: 'famagusta',
		area: 'agia napa',
		labelGR: 'Αγία Νάπα, Αμμόχωστος',
	},
	{
		label: 'Agia Thekla, Famagusta',
		id: '366',
		city: 'famagusta',
		area: 'agia thekla',
		labelGR: 'Αγία Θέκλα, Αμμόχωστος',
	},
	{
		label: 'Agia Triada, Famagusta',
		id: '367',
		city: 'famagusta',
		area: 'agia triada',
		labelGR: 'Αγία Τριάδα, Αμμόχωστος',
	},
	{
		label: 'Agia Zoni, Famagusta',
		id: '368',
		city: 'famagusta',
		area: 'agia zoni',
		labelGR: 'Αγία Ζώνη, Αμμόχωστος',
	},
	{
		label: 'Avgorou, Famagusta',
		id: '369',
		city: 'famagusta',
		area: 'avgorou',
		labelGR: 'Αυγόρου, Αμμόχωστος',
	},
	{
		label: 'Cape Greko, Famagusta',
		id: '370',
		city: 'famagusta',
		area: 'cape greko',
		labelGR: 'Κάβο Γκρέκο, Αμμόχωστος',
	},
	{
		label: 'Deryneia, Famagusta',
		id: '371',
		city: 'famagusta',
		area: 'deryneia',
		labelGR: 'Δερύνεια, Αμμόχωστος',
	},
	{
		label: 'Frenaros, Famagusta',
		id: '372',
		city: 'famagusta',
		area: 'frenaros',
		labelGR: 'Φρέναρος, Αμμόχωστος',
	},
	{
		label: 'Kapparis, Famagusta',
		id: '373',
		city: 'famagusta',
		area: 'kapparis',
		labelGR: 'Κάππαρη, Αμμόχωστος',
	},
	{
		label: 'Konnos, Famagusta',
		id: '374',
		city: 'famagusta',
		area: 'konnos',
		labelGR: 'Κόννος, Αμμόχωστος',
	},
	{
		label: 'Liopetri, Famagusta',
		id: '375',
		city: 'famagusta',
		area: 'liopetri',
		labelGR: 'Λιοπέτρι, Αμμόχωστος',
	},
	{
		label: 'Paralimni, Famagusta',
		id: '376',
		city: 'famagusta',
		area: 'paralimni',
		labelGR: 'Παραλίμνι, Αμμόχωστος',
	},
	{
		label: 'Pernera, Famagusta',
		id: '377',
		city: 'famagusta',
		area: 'pernera',
		labelGR: 'Περνέρα, Αμμόχωστος',
	},
	{
		label: 'Profitis Elias, Famagusta',
		id: '378',
		city: 'famagusta',
		area: 'profitis elias',
		labelGR: 'Προφήτης Ηλίας, Αμμόχωστος',
	},
	{
		label: 'Protaras, Famagusta',
		id: '379',
		city: 'famagusta',
		area: 'protaras',
		labelGR: 'Πρωταράς, Αμμόχωστος',
	},
	{
		label: 'Sotira, Famagusta',
		id: '380',
		city: 'famagusta',
		area: 'sotira',
		labelGR: 'Σωτήρα, Αμμόχωστος',
	},
	{
		label: 'Vrysoulles, Famagusta',
		id: '381',
		city: 'famagusta',
		area: 'vrysoulles',
		labelGR: 'Βρυσούλλες, Αμμόχωστος',
	},
]

export const LARNACA_AREAS = [
	{
		label: 'Agia Anna, Larnaca',
		id: '382',
		city: 'larnaca',
		area: 'agia anna',
		labelGR: 'Αγία Αννα, Λάρνακα',
	},
	{
		label: 'Agioi Anargyroi I, Larnaca',
		id: '383',
		city: 'larnaca',
		area: 'agioi anargyroi i',
		labelGR: 'Άγιοι Ανάργυροι, Λάρνακα',
	},
	{
		label: 'Agioi Vavatsinias, Larnaca',
		id: '384',
		city: 'larnaca',
		area: 'agioi vavatsinias',
		labelGR: 'Αγιοι Βαβατσινιάς, Λάρνακα',
	},
	{
		label: 'Agios Nikolaos, Larnaca',
		id: '385',
		city: 'larnaca',
		area: 'agios nikolaos',
		labelGR: 'Άγιος Νικόλαος, Λάρνακα',
	},
	{
		label: 'Agios Theodoros, Larnaca',
		id: '386',
		city: 'larnaca',
		area: 'agios theodoros',
		labelGR: 'Αγιος Θεόδωρος, Λάρνακα',
	},
	{
		label: 'Alaminos, Larnaca',
		id: '387',
		city: 'larnaca',
		area: 'alaminos',
		labelGR: 'Αλαμινός, Λάρνακα',
	},
	{
		label: 'Alethriko, Larnaca',
		id: '388',
		city: 'larnaca',
		area: 'alethriko',
		labelGR: 'Αλεθρικό, Λάρνακα',
	},
	{
		label: 'Anafotida, Larnaca',
		id: '389',
		city: 'larnaca',
		area: 'anafotida',
		labelGR: 'Αναφωτίδα, Λάρνακα',
	},
	{
		label: 'Anglikos Stratos Dekeleias, Larnaca',
		id: '390',
		city: 'larnaca',
		area: 'anglikos stratos dekeleias',
		labelGR: 'Αγγλικός Στρατός Δεκέλειας, Λάρνακα',
	},
	{
		label: 'Anglisides, Larnaca',
		id: '391',
		city: 'larnaca',
		area: 'anglisides',
		labelGR: 'Αγγλισίδες, Λάρνακα',
	},
	{
		label: 'Arch Makarios III, Larnaca',
		id: '392',
		city: 'larnaca',
		area: 'arch makarios iii',
		labelGR: 'Αρχιεπισκόπου Μακαρίου ΄Γ, Λάρνακα',
	},
	{
		label: 'Athienou, Larnaca',
		id: '393',
		city: 'larnaca',
		area: 'athienou',
		labelGR: 'Αθηένου, Λάρνακα',
	},
	{
		label: 'Avdellero, Larnaca',
		id: '394',
		city: 'larnaca',
		area: 'avdellero',
		labelGR: 'Αβδελλερό, Λάρνακα',
	},
	{
		label: 'Choirokoitia, Larnaca',
		id: '395',
		city: 'larnaca',
		area: 'choirokoitia',
		labelGR: 'Χοιροκοιτία, Λάρνακα',
	},
	{
		label: 'Chrysopolitissa, Larnaca',
		id: '396',
		city: 'larnaca',
		area: 'chrysopolitissa',
		labelGR: 'Παναγία Χρυσοπολίτισσα, Λάρνακα',
	},
	{
		label: 'Dekeleia, Larnaca',
		id: '397',
		city: 'larnaca',
		area: 'dekeleia',
		labelGR: 'Δεκέλεια, Λάρνακα',
	},
	{
		label: 'Dromolaxia, Larnaca',
		id: '398',
		city: 'larnaca',
		area: 'dromolaxia',
		labelGR: 'Δρομολαξιά, Λάρνακα',
	},
	{
		label: 'Drys Kato, Larnaca',
		id: '399',
		city: 'larnaca',
		area: 'drys kato',
		labelGR: 'Κάτω Δρυς, Λάρνακα',
	},
	{
		label: 'Finikoudes, Larnaca',
		id: '400',
		city: 'larnaca',
		area: 'finikoudes',
		labelGR: 'Φοινικούδες, Λάρνακα',
	},
	{
		label: 'Harbor, Larnaca',
		id: '401',
		city: 'larnaca',
		area: 'harbor',
		labelGR: 'Λιμάνι, Λάρνακα',
	},
	{
		label: 'Kalavasos, Larnaca',
		id: '402',
		city: 'larnaca',
		area: 'kalavasos',
		labelGR: 'Καλαβασός, Λάρνακα',
	},
	{
		label: 'Kalo Chorio, Larnaca',
		id: '403',
		city: 'larnaca',
		area: 'kalo chorio',
		labelGR: 'Καλό Χωριό, Λάρνακα',
	},
	{
		label: 'Kamares, Larnaca',
		id: '404',
		city: 'larnaca',
		area: 'kamares',
		labelGR: 'Καμάρες, Λάρνακα',
	},
	{
		label: 'Kiti, Larnaca',
		id: '405',
		city: 'larnaca',
		area: 'kiti',
		labelGR: 'Κίτι, Λάρνακα',
	},
	{
		label: 'Kivisili, Larnaca',
		id: '406',
		city: 'larnaca',
		area: 'kivisili',
		labelGR: 'Κιβισίλι, Λάρνακα',
	},
	{
		label: 'Kofinou, Larnaca',
		id: '407',
		city: 'larnaca',
		area: 'kofinou',
		labelGR: 'Κοφίνου, Λάρνακα',
	},
	{
		label: 'Kokkines, Larnaca',
		id: '408',
		city: 'larnaca',
		area: 'kokkines',
		labelGR: 'Κόκκινες, Λάρνακα',
	},
	{
		label: 'Kornos, Larnaca',
		id: '409',
		city: 'larnaca',
		area: 'kornos',
		labelGR: 'Κόρνος, Λάρνακα',
	},
	{
		label: 'Lageia, Larnaca',
		id: '410',
		city: 'larnaca',
		area: 'lageia',
		labelGR: 'Λάγεια, Λάρνακα',
	},
	{
		label: 'Lefkara Kato, Larnaca',
		id: '411',
		city: 'larnaca',
		area: 'lefkara kato',
		labelGR: 'Κάτω Λεύκαρα, Λάρνακα',
	},
	{
		label: 'Lefkara Pano, Larnaca',
		id: '412',
		city: 'larnaca',
		area: 'lefkara pano',
		labelGR: 'Πάνω Λεύκαρα, Λάρνακα',
	},
	{
		label: 'Livadia, Larnaca',
		id: '413',
		city: 'larnaca',
		area: 'livadia',
		labelGR: 'Λιβάδια, Λάρνακα',
	},
	{
		label: 'Makenzy, Larnaca',
		id: '414',
		city: 'larnaca',
		area: 'makenzy',
		labelGR: 'Μακένζυ, Λάρνακα',
	},
	{
		label: 'Maroni, Larnaca',
		id: '415',
		city: 'larnaca',
		area: 'maroni',
		labelGR: 'Μαρώνι, Λάρνακα',
	},
	{
		label: 'Mazotos, Larnaca',
		id: '416',
		city: 'larnaca',
		area: 'mazotos',
		labelGR: 'Μαζωτός, Λάρνακα',
	},
	{
		label: 'Melini, Larnaca',
		id: '417',
		city: 'larnaca',
		area: 'melini',
		labelGR: 'Μελίνη, Λάρνακα',
	},
	{
		label: 'Meneou, Larnaca',
		id: '418',
		city: 'larnaca',
		area: 'meneou',
		labelGR: 'Μενεού, Λάρνακα',
	},
	{
		label: 'Mosfiloti, Larnaca',
		id: '419',
		city: 'larnaca',
		area: 'mosfiloti',
		labelGR: 'Μοσφιλωτή, Λάρνακα',
	},
	{
		label: 'Ora, Larnaca',
		id: '420',
		city: 'larnaca',
		area: 'ora',
		labelGR: 'Ορά, Λάρνακα',
	},
	{
		label: 'Ormideia, Larnaca',
		id: '421',
		city: 'larnaca',
		area: 'ormideia',
		labelGR: 'Ορμήδεια, Λάρνακα',
	},
	{
		label: 'Oroklini, Larnaca',
		id: '422',
		city: 'larnaca',
		area: 'oroklini',
		labelGR: 'Ορόκλινη, Λάρνακα',
	},
	{
		label: 'Perivolia, Larnaca',
		id: '423',
		city: 'larnaca',
		area: 'perivolia',
		labelGR: 'Περβόλια, Λάρνακα',
	},
	{
		label: 'Petrofani, Larnaca',
		id: '424',
		city: 'larnaca',
		area: 'petrofani',
		labelGR: 'Πετροφάνι, Λάρνακα',
	},
	{
		label: 'Psematismenos, Larnaca',
		id: '425',
		city: 'larnaca',
		area: 'psematismenos',
		labelGR: 'Ψεματισμένος, Λάρνακα',
	},
	{
		label: 'Psevdas, Larnaca',
		id: '426',
		city: 'larnaca',
		area: 'psevdas',
		labelGR: 'Ψευδάς, Λάρνακα',
	},
	{
		label: 'Pyla, Larnaca',
		id: '427',
		city: 'larnaca',
		area: 'pyla',
		labelGR: 'Πύλα, Λάρνακα',
	},
	{
		label: 'Pyrga, Larnaca',
		id: '428',
		city: 'larnaca',
		area: 'pyrga',
		labelGR: 'Πυργά, Λάρνακα',
	},
	{
		label: 'Skala, Larnaca',
		id: '429',
		city: 'larnaca',
		area: 'skala',
		labelGR: 'Σκάλα, Λάρνακα',
	},
	{
		label: 'Skarinou, Larnaca',
		id: '430',
		city: 'larnaca',
		area: 'skarinou',
		labelGR: 'Σκαρίνου, Λάρνακα',
	},
	{
		label: 'Softades, Larnaca',
		id: '431',
		city: 'larnaca',
		area: 'softades',
		labelGR: 'Σοφτάδες, Λάρνακα',
	},
	{
		label: 'Sotiros, Larnaca',
		id: '432',
		city: 'larnaca',
		area: 'sotiros',
		labelGR: 'Σωτήρος, Λάρνακα',
	},
	{
		label: 'Stathmos Skarinou, Larnaca',
		id: '433',
		city: 'larnaca',
		area: 'stathmos skarinou',
		labelGR: 'Σταθμός Σκαρίνου, Λάρνακα',
	},
	{
		label: 'Tersefanou, Larnaca',
		id: '434',
		city: 'larnaca',
		area: 'tersefanou',
		labelGR: 'Τερσεφάνου, Λάρνακα',
	},
	{
		label: 'Tochni, Larnaca',
		id: '435',
		city: 'larnaca',
		area: 'tochni',
		labelGR: 'Τόχνη, Λάρνακα',
	},
	{
		label: 'Troulloi, Larnaca',
		id: '436',
		city: 'larnaca',
		area: 'troulloi',
		labelGR: 'Τρούλλοι, Λάρνακα',
	},
	{
		label: 'Tsakilero, Larnaca',
		id: '437',
		city: 'larnaca',
		area: 'tsakilero',
		labelGR: 'Τσιακκιλερο, Λάρνακα',
	},
	{
		label: 'Vavatsinia, Larnaca',
		id: '438',
		city: 'larnaca',
		area: 'vavatsinia',
		labelGR: 'Βαβατσινιά, Λάρνακα',
	},
	{
		label: 'Vavla, Larnaca',
		id: '439',
		city: 'larnaca',
		area: 'vavla',
		labelGR: 'Βάβλα, Λάρνακα',
	},
	{
		label: 'Xylofagou, Larnaca',
		id: '440',
		city: 'larnaca',
		area: 'xylofagou',
		labelGR: 'Ξυλοφάγου, Λάρνακα',
	},
	{
		label: 'Xylotymvou, Larnaca',
		id: '441',
		city: 'larnaca',
		area: 'xylotymvou',
		labelGR: 'Ξυλοτύμπου, Λάρνακα',
	},
	{
		label: 'Zygi, Larnaca',
		id: '442',
		city: 'larnaca',
		area: 'zygi',
		labelGR: 'Ζύγι, Λάρνακα',
	},
	{
		label: 'Aradippou, Larnaca',
		id: '445',
		city: 'larnaca',
		area: 'aradippou',
		labelGR: 'Αραδίππου, Λάρνακα',
	},
]

export const LIMASSOL_AREAS = [
	{
		label: 'Agia Fyla, Limassol',
		id: '125',
		city: 'limassol',
		area: 'agia fyla',
		labelGR: 'Αγία Φύλα, Λεμεσός',
	},
	{
		label: 'Agia Napa, Limassol',
		id: '126',
		city: 'limassol',
		area: 'agia napa',
		labelGR: 'Αγία Νάπα, Λεμεσός',
	},
	{
		label: 'Agia Paraskevi, Limassol',
		id: '127',
		city: 'limassol',
		area: 'agia paraskevi',
		labelGR: 'Αγία Παρασκευή, Λεμεσός',
	},
	{
		label: 'Agia Triada, Limassol',
		id: '128',
		city: 'limassol',
		area: 'agia triada',
		labelGR: 'Αγία Τριάδα, Λεμεσός',
	},
	{
		label: 'Agios Amvrosios, Limassol',
		id: '129',
		city: 'limassol',
		area: 'agios amvrosios',
		labelGR: 'Άγιος Αμβρόσιος, Λεμεσός',
	},
	{
		label: 'Agios Antonios, Limassol',
		id: '130',
		city: 'limassol',
		area: 'agios antonios',
		labelGR: 'Άγιος Αντώνιος, Λεμεσός',
	},
	{
		label: 'Agios Athanasios, Limassol',
		id: '131',
		city: 'limassol',
		area: 'agios athanasios',
		labelGR: 'Άγιος Αθανάσιος, Λεμεσός',
	},
	{
		label: 'Agios Georgios, Limassol',
		id: '132',
		city: 'limassol',
		area: 'agios georgios',
		labelGR: 'Άγιος Γεώργιος, Λεμεσός',
	},
	{
		label: 'Agios Georgios, Limassol',
		id: '133',
		city: 'limassol',
		area: 'agios georgios',
		labelGR: 'Άγιος Γεώργιος, Λεμεσός',
	},
	{
		label: 'Agios Ioannis, Limassol',
		id: '134',
		city: 'limassol',
		area: 'agios ioannis',
		labelGR: 'Άγιος Ιωάννης, Λεμεσός',
	},
	{
		label: 'Agios Ioannis, Limassol',
		id: '135',
		city: 'limassol',
		area: 'agios ioannis',
		labelGR: 'Άγιος Ιωάννης, Λεμεσός',
	},
	{
		label: 'Agios Konstantinos, Limassol',
		id: '136',
		city: 'limassol',
		area: 'agios konstantinos',
		labelGR: 'Άγιος Κωνσταντίνος, Λεμεσός',
	},
	{
		label: 'Agios Mamas, Limassol',
		id: '137',
		city: 'limassol',
		area: 'agios mamas',
		labelGR: 'Άγιος Μάμας, Λεμεσός',
	},
	{
		label: 'Agios Nektarios, Limassol',
		id: '138',
		city: 'limassol',
		area: 'agios nektarios',
		labelGR: 'Άγιος Νεκτάριος, Λεμεσός',
	},
	{
		label: 'Agios Nicolaos, Limassol',
		id: '139',
		city: 'limassol',
		area: 'agios nicolaos',
		labelGR: 'Άγιος Νικόλαος, Λεμεσός',
	},
	{
		label: 'Agios Pavlos, Limassol',
		id: '140',
		city: 'limassol',
		area: 'agios pavlos',
		labelGR: 'Άγιος Παύλος, Λεμεσός',
	},
	{
		label: 'Agios Spyridon, Limassol',
		id: '141',
		city: 'limassol',
		area: 'agios spyridon',
		labelGR: 'Άγιος Σπυρίδωνας, Λεμεσός',
	},
	{
		label: 'Agios Stylianos, Limassol',
		id: '142',
		city: 'limassol',
		area: 'agios stylianos',
		labelGR: 'Άγιος Στυλιανός, Λεμεσός',
	},
	{
		label: 'Agios Theodoros, Limassol',
		id: '143',
		city: 'limassol',
		area: 'agios theodoros',
		labelGR: 'Άγιος Θεόδωρος, Λεμεσός',
	},
	{
		label: 'Agios Therapon, Limassol',
		id: '144',
		city: 'limassol',
		area: 'agios therapon',
		labelGR: 'Άγιος Θεράπων, Λεμεσός',
	},
	{
		label: 'Agios Tychon Tourist Area, Limassol',
		id: '145',
		city: 'limassol',
		area: 'agios tychon tourist area',
		labelGR: 'Άγιος Τύχων, Λεμεσός',
	},
	{
		label: 'Agios Tychon, Limassol',
		id: '146',
		city: 'limassol',
		area: 'agios tychon',
		labelGR: 'Άγιος Τύχων, Λεμεσός',
	},
	{
		label: 'Agros, Limassol',
		id: '147',
		city: 'limassol',
		area: 'agros',
		labelGR: 'Αγρός, Λεμεσός',
	},
	{
		label: 'Akapnou, Limassol',
		id: '148',
		city: 'limassol',
		area: 'akapnou',
		labelGR: 'Ακαπνού, Λεμεσός',
	},
	{
		label: 'Akrotiri, Limassol',
		id: '149',
		city: 'limassol',
		area: 'akrotiri',
		labelGR: 'Ακρωτήρι, Λεμεσός',
	},
	{
		label: 'Akrounta, Limassol',
		id: '150',
		city: 'limassol',
		area: 'akrounta',
		labelGR: 'Ακρούντα, Λεμεσός',
	},
	{
		label: 'Alassa, Limassol',
		id: '151',
		city: 'limassol',
		area: 'alassa',
		labelGR: 'Άλασσα, Λεμεσός',
	},
	{
		label: 'Amiantos Kato, Limassol',
		id: '152',
		city: 'limassol',
		area: 'amiantos kato',
		labelGR: 'Κατω Αμιαντος, Λεμεσός',
	},
	{
		label: 'Anogyra, Limassol',
		id: '153',
		city: 'limassol',
		area: 'anogyra',
		labelGR: 'Ανώγυρα, Λεμεσός',
	},
	{
		label: 'Apesia, Limassol',
		id: '154',
		city: 'limassol',
		area: 'apesia',
		labelGR: 'Απεσιά, Λεμεσός',
	},
	{
		label: 'Apostolos Andreas, Limassol',
		id: '155',
		city: 'limassol',
		area: 'apostolos andreas',
		labelGR: 'Απόστολος Ανδρέας, Λεμεσός',
	},
	{
		label: 'Apsiou, Limassol',
		id: '156',
		city: 'limassol',
		area: 'apsiou',
		labelGR: 'Αψιού, Λεμεσός',
	},
	{
		label: 'Arakapas, Limassol',
		id: '157',
		city: 'limassol',
		area: 'arakapas',
		labelGR: 'Αρακαπάς, Λεμεσός',
	},
	{
		label: 'Armenochori, Limassol',
		id: '158',
		city: 'limassol',
		area: 'armenochori',
		labelGR: 'Αρμενοχώρι, Λεμεσός',
	},
	{
		label: 'Arnaoutogeitonia, Limassol',
		id: '159',
		city: 'limassol',
		area: 'arnaoutogeitonia',
		labelGR: 'Αρναουτογειτονιά, Λεμεσός',
	},
	{
		label: 'Arsos Lemesou, Limassol',
		id: '160',
		city: 'limassol',
		area: 'arsos lemesou',
		labelGR: 'Αρσος, Λεμεσός',
	},
	{
		label: 'Asgata, Limassol',
		id: '161',
		city: 'limassol',
		area: 'asgata',
		labelGR: 'Ασγάτα, Λεμεσός',
	},
	{
		label: 'Asomatos, Limassol',
		id: '162',
		city: 'limassol',
		area: 'asomatos',
		labelGR: 'Ασώματος, Λεμεσός',
	},
	{
		label: 'Avdimou, Limassol',
		id: '163',
		city: 'limassol',
		area: 'avdimou',
		labelGR: 'Αυδήμου, Λεμεσός',
	},
	{
		label: 'Chalkoutsa, Limassol',
		id: '164',
		city: 'limassol',
		area: 'chalkoutsa',
		labelGR: 'Χαλκούτσα, Λεμεσός',
	},
	{
		label: 'Chandria, Limassol',
		id: '165',
		city: 'limassol',
		area: 'chandria',
		labelGR: 'Χανδριά, Λεμεσός',
	},
	{
		label: 'Dierona, Limassol',
		id: '166',
		city: 'limassol',
		area: 'dierona',
		labelGR: 'Διερώνα, Λεμεσός',
	},
	{
		label: 'Dora, Limassol',
		id: '167',
		city: 'limassol',
		area: 'dora',
		labelGR: 'Δωρά, Λεμεσός',
	},
	{
		label: 'Doros, Limassol',
		id: '168',
		city: 'limassol',
		area: 'doros',
		labelGR: 'Δωρός, Λεμεσός',
	},
	{
		label: 'Ekali, Limassol',
		id: '169',
		city: 'limassol',
		area: 'ekali',
		labelGR: 'Εκάλη, Λεμεσός',
	},
	{
		label: 'Episkopi, Limassol',
		id: '170',
		city: 'limassol',
		area: 'episkopi',
		labelGR: 'Επισκοπή, Λεμεσός',
	},
	{
		label: 'Eptagoneia, Limassol',
		id: '171',
		city: 'limassol',
		area: 'eptagoneia',
		labelGR: 'Επταγώνεια, Λεμεσός',
	},
	{
		label: 'Erimi, Limassol',
		id: '172',
		city: 'limassol',
		area: 'erimi',
		labelGR: 'Ερήμη, Λεμεσός',
	},
	{
		label: 'Fasoula, Limassol',
		id: '173',
		city: 'limassol',
		area: 'fasoula',
		labelGR: 'Φασούλα, Λεμεσός',
	},
	{
		label: 'Fasouri, Limassol',
		id: '174',
		city: 'limassol',
		area: 'fasouri',
		labelGR: 'Φασούρι, Λεμεσός',
	},
	{
		label: 'Foini, Limassol',
		id: '175',
		city: 'limassol',
		area: 'foini',
		labelGR: 'Φοινί, Λεμεσός',
	},
	{
		label: 'Foinikaria, Limassol',
		id: '176',
		city: 'limassol',
		area: 'foinikaria',
		labelGR: 'Φοινικάρια, Λεμεσός',
	},
	{
		label: 'Gerasa, Limassol',
		id: '177',
		city: 'limassol',
		area: 'gerasa',
		labelGR: 'Γέρασα, Λεμεσός',
	},
	{
		label: 'Germasogeia Tourist Area, Limassol',
		id: '178',
		city: 'limassol',
		area: 'germasogeia tourist area',
		labelGR: 'Γερμασόγεια Τουριστική Περιοχή, Λεμεσός',
	},
	{
		label: 'Germasogeia, Limassol',
		id: '179',
		city: 'limassol',
		area: 'germasogeia',
		labelGR: 'Γέρασα, Λεμεσός',
	},
	{
		label: 'Historical Center, Limassol',
		id: '180',
		city: 'limassol',
		area: 'historical center',
		labelGR: 'Δημοτικός Κήπος, Λεμεσός',
	},
	{
		label: 'Kalo Chorio, Limassol',
		id: '181',
		city: 'limassol',
		area: 'kalo chorio',
		labelGR: 'Καλό Χωριό, Λεμεσός',
	},
	{
		label: 'Kaminaria, Limassol',
		id: '182',
		city: 'limassol',
		area: 'kaminaria',
		labelGR: 'Καμινάρια, Λεμεσός',
	},
	{
		label: 'Kapileio, Limassol',
		id: '183',
		city: 'limassol',
		area: 'kapileio',
		labelGR: 'Καπηλιό, Λεμεσός',
	},
	{
		label: 'Kapsalos, Limassol',
		id: '184',
		city: 'limassol',
		area: 'kapsalos',
		labelGR: 'Κάψαλος, Λεμεσός',
	},
	{
		label: 'Katholiki, Limassol',
		id: '185',
		city: 'limassol',
		area: 'katholiki',
		labelGR: 'Καθολική, Λεμεσός',
	},
	{
		label: 'Kellaki, Limassol',
		id: '186',
		city: 'limassol',
		area: 'kellaki',
		labelGR: 'Κελλάκι, Λεμεσός',
	},
	{
		label: 'Kissousa, Limassol',
		id: '187',
		city: 'limassol',
		area: 'kissousa',
		labelGR: 'Κισσούσα, Λεμεσός',
	},
	{
		label: 'Kivides Pano, Limassol',
		id: '188',
		city: 'limassol',
		area: 'kivides pano',
		labelGR: 'Πάνω Κιβίδες, Λεμεσός',
	},
	{
		label: 'Klonari, Limassol',
		id: '189',
		city: 'limassol',
		area: 'klonari',
		labelGR: 'Κλωνάρι, Λεμεσός',
	},
	{
		label: 'Koilani, Limassol',
		id: '190',
		city: 'limassol',
		area: 'koilani',
		labelGR: 'Κοιλάνι, Λεμεσός',
	},
	{
		label: 'Kolossi, Limassol',
		id: '191',
		city: 'limassol',
		area: 'kolossi',
		labelGR: 'Κολόσσι, Λεμεσός',
	},
	{
		label: 'Kontovathkeia, Limassol',
		id: '192',
		city: 'limassol',
		area: 'kontovathkeia',
		labelGR: 'Κοντοβάθκια, Λεμεσός',
	},
	{
		label: 'Korfi, Limassol',
		id: '193',
		city: 'limassol',
		area: 'korfi',
		labelGR: 'Κορφή, Λεμεσός',
	},
	{
		label: 'Kouka, Limassol',
		id: '194',
		city: 'limassol',
		area: 'kouka',
		labelGR: 'Κουκά, Λεμεσός',
	},
	{
		label: 'Kyperounta, Limassol',
		id: '195',
		city: 'limassol',
		area: 'kyperounta',
		labelGR: 'Κυπερουντα, Λεμεσός',
	},
	{
		label: 'Laneia, Limassol',
		id: '196',
		city: 'limassol',
		area: 'laneia',
		labelGR: 'Λάνεια, Λεμεσός',
	},
	{
		label: 'Limnatis, Limassol',
		id: '197',
		city: 'limassol',
		area: 'limnatis',
		labelGR: 'Λιμνάτης, Λεμεσός',
	},
	{
		label: 'Linopetra, Limassol',
		id: '198',
		city: 'limassol',
		area: 'linopetra',
		labelGR: 'Λινόπετρα, Λεμεσός',
	},
	{
		label: 'Lofou, Limassol',
		id: '199',
		city: 'limassol',
		area: 'lofou',
		labelGR: 'Λόφου, Λεμεσός',
	},
	{
		label: 'Louvaras, Limassol',
		id: '200',
		city: 'limassol',
		area: 'louvaras',
		labelGR: 'Λουβαράς, Λεμεσός',
	},
	{
		label: 'Mandria, Limassol',
		id: '201',
		city: 'limassol',
		area: 'mandria',
		labelGR: 'Μανδριά, Λεμεσός',
	},
	{
		label: 'Marina, Limassol',
		id: '202',
		city: 'limassol',
		area: 'marina',
		labelGR: 'Μαρίνα, Λεμεσός',
	},
	{
		label: 'Mathikoloni, Limassol',
		id: '203',
		city: 'limassol',
		area: 'mathikoloni',
		labelGR: 'Μαθηκολώνη, Λεμεσός',
	},
	{
		label: 'Mesa Geitonia, Limassol',
		id: '204',
		city: 'limassol',
		area: 'mesa geitonia',
		labelGR: 'Μέσα Γειτονιά, Λεμεσός',
	},
	{
		label: 'Mesa Geitonia, Limassol',
		id: '205',
		city: 'limassol',
		area: 'mesa geitonia',
		labelGR: 'Μέσα Γειτονιά, Λεμεσός',
	},
	{
		label: 'Monagri, Limassol',
		id: '206',
		city: 'limassol',
		area: 'monagri',
		labelGR: 'Μονάγρι, Λεμεσός',
	},
	{
		label: 'Monagroulli, Limassol',
		id: '207',
		city: 'limassol',
		area: 'monagroulli',
		labelGR: 'Μοναγρούλλι, Λεμεσός',
	},
	{
		label: 'Moni Agiou Georgiou Alamanou, Limassol',
		id: '208',
		city: 'limassol',
		area: 'moni agiou georgiou alamanou',
		labelGR: 'Ιερά Μονή Αγίου Γεωργίου του Αλαμάνου, Λεμεσός',
	},
	{
		label: 'Moni, Limassol',
		id: '209',
		city: 'limassol',
		area: 'moni',
		labelGR: 'Μονή, Λεμεσός',
	},
	{
		label: 'Moniatis, Limassol',
		id: '210',
		city: 'limassol',
		area: 'moniatis',
		labelGR: 'Μονιάτης, Λεμεσός',
	},
	{
		label: 'Mouttagiaka Tourist Area, Limassol',
		id: '211',
		city: 'limassol',
		area: 'mouttagiaka tourist area',
		labelGR: 'Μουτταγιάκα Τουριστική Περιοχή, Λεμεσός',
	},
	{
		label: 'Mouttagiaka, Limassol',
		id: '212',
		city: 'limassol',
		area: 'mouttagiaka',
		labelGR: 'Μουτταγιάκα, Λεμεσός',
	},
	{
		label: 'Mylos Kato, Limassol',
		id: '213',
		city: 'limassol',
		area: 'mylos kato',
		labelGR: 'Κάτω Μυλος, Λεμεσός',
	},
	{
		label: 'Neapolis, Limassol',
		id: '214',
		city: 'limassol',
		area: 'neapolis',
		labelGR: 'Νεάπολη, Λεμεσός',
	},
	{
		label: 'Omodos, Limassol',
		id: '215',
		city: 'limassol',
		area: 'omodos',
		labelGR: 'Όμοδος, Λεμεσός',
	},
	{
		label: 'Omonia, Limassol',
		id: '216',
		city: 'limassol',
		area: 'omonia',
		labelGR: 'Ομόνοια, Λεμεσός',
	},
	{
		label: 'Pachna, Limassol',
		id: '217',
		city: 'limassol',
		area: 'pachna',
		labelGR: 'Πάχνα, Λεμεσός',
	},
	{
		label: 'Palodeia, Limassol',
		id: '218',
		city: 'limassol',
		area: 'palodeia',
		labelGR: 'Παλώδια, Λεμεσός',
	},
	{
		label: 'Panag. Evangelistria, Limassol',
		id: '219',
		city: 'limassol',
		area: 'panag. evangelistria',
		labelGR: 'Παναγία Ευαγγελίστρια, Λεμεσός',
	},
	{
		label: 'Panthea, Limassol',
		id: '220',
		city: 'limassol',
		area: 'panthea',
		labelGR: 'Πάνθεα, Λεμεσός',
	},
	{
		label: 'Paramali, Limassol',
		id: '221',
		city: 'limassol',
		area: 'paramali',
		labelGR: 'Παραμάλι, Λεμεσός',
	},
	{
		label: 'Paramytha, Limassol',
		id: '222',
		city: 'limassol',
		area: 'paramytha',
		labelGR: 'Παραμύθα, Λεμεσός',
	},
	{
		label: 'Parekklisia Tourist Area, Limassol',
		id: '223',
		city: 'limassol',
		area: 'parekklisia tourist area',
		labelGR: 'Παρεκλησιά Tουριστική Περιοχή, Λεμεσός',
	},
	{
		label: 'Parekklisia, Limassol',
		id: '224',
		city: 'limassol',
		area: 'parekklisia',
		labelGR: 'Παρεκλησιά, Λεμεσός',
	},
	{
		label: 'Pelendri, Limassol',
		id: '225',
		city: 'limassol',
		area: 'pelendri',
		labelGR: 'Πελένδρι, Λεμεσός',
	},
	{
		label: 'Pentakomo, Limassol',
		id: '226',
		city: 'limassol',
		area: 'pentakomo',
		labelGR: 'Πεντάκωμο, Λεμεσός',
	},
	{
		label: 'Pera Pedi, Limassol',
		id: '227',
		city: 'limassol',
		area: 'pera pedi',
		labelGR: 'Πέρα Πεδί, Λεμεσός',
	},
	{
		label: 'Petrou Kai Pavlou, Limassol',
		id: '228',
		city: 'limassol',
		area: 'petrou kai pavlou',
		labelGR: 'Πέτρου και Παύλου, Λεμεσός',
	},
	{
		label: 'Pissouri, Limassol',
		id: '229',
		city: 'limassol',
		area: 'pissouri',
		labelGR: 'Πισσούρι, Λεμεσός',
	},
	{
		label: 'Platres Kato, Limassol',
		id: '230',
		city: 'limassol',
		area: 'platres kato',
		labelGR: 'Κάτω Πλάτρες, Λεμεσός',
	},
	{
		label: 'Platres Pano, Limassol',
		id: '231',
		city: 'limassol',
		area: 'platres pano',
		labelGR: 'Πάνω Πλάτρες, Λεμεσός',
	},
	{
		label: 'Polemidia Kato, Limassol',
		id: '232',
		city: 'limassol',
		area: 'polemidia kato',
		labelGR: 'Κάτω Πολεμίδια, Λεμεσός',
	},
	{
		label: 'Polemidia Pano, Limassol',
		id: '233',
		city: 'limassol',
		area: 'polemidia pano',
		labelGR: 'Πάνω Πολεμίδια, Λεμεσός',
	},
	{
		label: 'Polemidia, Limassol',
		id: '234',
		city: 'limassol',
		area: 'polemidia',
		labelGR: 'Πολεμίδια, Λεμεσός',
	},
	{
		label: 'Potamiou, Limassol',
		id: '235',
		city: 'limassol',
		area: 'potamiou',
		labelGR: 'Ποταμιού, Λεμεσός',
	},
	{
		label: 'Potamitissa, Limassol',
		id: '236',
		city: 'limassol',
		area: 'potamitissa',
		labelGR: 'Ποταμιτισσα, Λεμεσός',
	},
	{
		label: 'Potamos Germasogeias, Limassol',
		id: '237',
		city: 'limassol',
		area: 'potamos germasogeias',
		labelGR: 'Ποταμός της Γερμασόγειας, Λεμεσός',
	},
	{
		label: 'Prastio Avdimou, Limassol',
		id: '238',
		city: 'limassol',
		area: 'prastio avdimou',
		labelGR: 'Πραστιό Αυδήμου, Λεμεσός',
	},
	{
		label: 'Prodromos, Limassol',
		id: '239',
		city: 'limassol',
		area: 'prodromos',
		labelGR: 'Πρόδρομος, Λεμεσός',
	},
	{
		label: 'Pyrgos Tourist Area, Limassol',
		id: '240',
		city: 'limassol',
		area: 'pyrgos tourist area',
		labelGR: 'Πύργος Τουριστική Περιοχή, Λεμεσός',
	},
	{
		label: 'Pyrgos, Limassol',
		id: '241',
		city: 'limassol',
		area: 'pyrgos',
		labelGR: 'Πύργος, Λεμεσός',
	},
	{
		label: 'Saittas, Limassol',
		id: '242',
		city: 'limassol',
		area: 'saittas',
		labelGR: 'Σαιττας, Λεμεσός',
	},
	{
		label: 'Sanida, Limassol',
		id: '243',
		city: 'limassol',
		area: 'sanida',
		labelGR: 'Σανίδα, Λεμεσός',
	},
	{
		label: 'Silikou, Limassol',
		id: '244',
		city: 'limassol',
		area: 'silikou',
		labelGR: 'Σιλίκου, Λεμεσός',
	},
	{
		label: 'Sotira, Limassol',
		id: '245',
		city: 'limassol',
		area: 'sotira',
		labelGR: 'Σωτήρα, Λεμεσός',
	},
	{
		label: 'Souni, Limassol',
		id: '246',
		city: 'limassol',
		area: 'souni',
		labelGR: 'Σούνι, Λεμεσός',
	},
	{
		label: 'Spitali, Limassol',
		id: '247',
		city: 'limassol',
		area: 'spitali',
		labelGR: 'Σπιτάλι, Λεμεσός',
	},
	{
		label: 'Sykopetra, Limassol',
		id: '248',
		city: 'limassol',
		area: 'sykopetra',
		labelGR: 'Συκόπετρα, Λεμεσός',
	},
	{
		label: 'Timiou Prodromou Mesa Geitonias, Limassol',
		id: '249',
		city: 'limassol',
		area: 'timiou prodromou mesa geitonias',
		labelGR: 'Τίμιος Πρόδρομος Μέσα Γειτονιά, Λεμεσός',
	},
	{
		label: 'Trachoni Lemesou, Limassol',
		id: '250',
		city: 'limassol',
		area: 'trachoni lemesou',
		labelGR: 'Τραχώνι, Λεμεσός',
	},
	{
		label: 'Treis Elies, Limassol',
		id: '251',
		city: 'limassol',
		area: 'treis elies',
		labelGR: 'Τρεις Ελιές, Λεμεσός',
	},
	{
		label: 'Trimiklini, Limassol',
		id: '252',
		city: 'limassol',
		area: 'trimiklini',
		labelGR: 'Τριμίκλινη, Λεμεσός',
	},
	{
		label: 'Troodos, Limassol',
		id: '253',
		city: 'limassol',
		area: 'troodos',
		labelGR: 'Τρόοδος, Λεμεσός',
	},
	{
		label: 'Tsiflikoudia, Limassol',
		id: '254',
		city: 'limassol',
		area: 'tsiflikoudia',
		labelGR: 'Τσιφλικούδια, Λεμεσός',
	},
	{
		label: 'Tsirion, Limassol',
		id: '255',
		city: 'limassol',
		area: 'tsirion',
		labelGR: 'Τσίρειο Στάδιο, Λεμεσός',
	},
	{
		label: 'Tzami Tzentit, Limassol',
		id: '256',
		city: 'limassol',
		area: 'tzami tzentit',
		labelGR: 'Τζαμί Τζεντίτ, Λεμεσός',
	},
	{
		label: 'Vasa Kellakiou, Limassol',
		id: '257',
		city: 'limassol',
		area: 'vasa kellakiou',
		labelGR: 'Βάσα Κελλακίου, Λεμεσός',
	},
	{
		label: 'Vasa Koilaniou, Limassol',
		id: '258',
		city: 'limassol',
		area: 'vasa koilaniou',
		labelGR: 'Βάσα Κοιλανίου, Λεμεσός',
	},
	{
		label: 'Vikla, Limassol',
		id: '259',
		city: 'limassol',
		area: 'vikla',
		labelGR: 'Βίκλα, Λεμεσός',
	},
	{
		label: 'Vouni, Limassol',
		id: '260',
		city: 'limassol',
		area: 'vouni',
		labelGR: 'Βουνί, Λεμεσός',
	},
	{
		label: 'Ypsonas, Limassol',
		id: '261',
		city: 'limassol',
		area: 'ypsonas',
		labelGR: 'Ύψωνας, Λεμεσός',
	},
	{
		label: 'Zakaki, Limassol',
		id: '262',
		city: 'limassol',
		area: 'zakaki',
		labelGR: 'Ζακάκι, Λεμεσός',
	},
	{
		label: 'Zanakia, Limassol',
		id: '263',
		city: 'limassol',
		area: 'zanakia',
		labelGR: 'Ζανακιά, Λεμεσός',
	},
	{
		label: 'Agia Zoni, Limassol',
		id: '443',
		city: 'limassol',
		area: 'agia zoni',
		labelGR: 'Αγία Ζώνη, Λεμεσός',
	},
	{
		label: 'Zoopigi, Limassol',
		id: '446',
		city: 'limassol',
		area: 'zoopigi',
		labelGR: 'Ζωοπηγή, Λεμεσός'
	}
]

const CITIES = [
	{ label: 'Nicosia', labelGR: 'Λευκωσία', type: 'city', id: '0', city: 'nicosia', area: 'all' },
	{ label: 'Limassol', labelGR: 'Λεμεσός', type: 'city', id: '1', city: 'limassol', area: 'all' },
	{ label: 'Paphos', labelGR: 'Πάφος', type: 'city', id: '2', city: 'paphos', area: 'all' },
	{ label: 'Famagusta', labelGR: 'Αμμόχωστος', type: 'city', id: '3', city: 'famagusta', area: 'all' },
	{ label: 'Larnaca', labelGR: 'Λάρνακα', type: 'city', id: '4', city: 'larnaca', area: 'all' }
]

export const SEARCH_AREAS:AreaOption[] = [
	...CITIES,
	...NICOSIA_AREAS,
	...LIMASSOL_AREAS,
	...PAPHOS_AREAS,
	...FAMAGUSTA_AREAS,
	...LARNACA_AREAS,
]
